import React, { useState, useEffect, useRef } from 'react';
import {
  Text,
  TouchableOpacity,
  View,
  ActivityIndicator,
  Platform,
} from 'react-native';

import { createPriceLogic, updatePriceLogic } from '../../../../api/Price';
import { BasicModalContainer, ModalHeader, ModalBody, ModalFooter, commonModalStyle } from '../../../../common/components/basicmodal';
import { msgStr } from '../../../../common/constants/Message';
import { useAlertModal } from '../../../../common/hooks';
import { Picker } from '@react-native-picker/picker';

import LabeledTextInput from '../../../../common/components/bohform/LabeledTextInput';
import { renderBOHFormDatePicker } from '../../../../common/components/bohform';
import { formatDate } from '../../../../common/utils/DateUtils';
import NumericInput from '../../../../common/components/formcomponents/NumericInput';

interface FormValues {
  brand_id: number | undefined;
  season_id: number | undefined;
  table_id: number | undefined;
  start_date: Date;
  end_date: Date;
}

const EditPriceLogicModal = ({
  isModalVisible,
  PriceLogic,
  setUpdatePriceLogicTrigger,
  closeModal,
  brandData,
  seasonData,
  priceTableData
}) => {
  const isUpdate = PriceLogic ? true : false;
  const inputRef = useRef(null);

  const { showAlert } = useAlertModal();
  const [ValidMessage, setValidMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [formValues, setFormValues] = useState<FormValues>({
    brand_id: undefined,
    season_id: undefined,
    table_id: undefined,
    start_date: new Date(),
    end_date: new Date(),
  });

  useEffect(() => {
    if (isModalVisible && PriceLogic) {
      setFormValues({
        brand_id: PriceLogic.brand_id,
        season_id: PriceLogic.season_id,
        table_id: PriceLogic.table_id,
        start_date: PriceLogic?.start_date? new Date(`${PriceLogic.start_date} 00:00:00`) : new Date(),
        end_date: PriceLogic?.end_date? new Date(`${PriceLogic.end_date} 00:00:00`) : new Date(),
      })
    } else {
      setFormValues({
        brand_id: undefined,
        season_id: undefined,
        table_id: undefined,
        start_date: new Date(),
        end_date: new Date(),
      })
    }
  }, [isModalVisible]);

  const changeFormValue = (name, value) => {
    setFormValues(prev => ({
      ...prev,
      [name]:value,
    }))
  }

  const AddButtonHandler = () => {
    setIsLoading(true);

    const handleResponse = (jsonRes, status) => {
      switch (status) {
        case 201:
          showAlert('success', jsonRes.message);
          setUpdatePriceLogicTrigger(true);
          closeModal();
          break;
        case 409:
          setValidMessage(jsonRes.error);
          break;
        default:
          if (jsonRes && jsonRes.error) showAlert('error', jsonRes.error);
          else showAlert('error', msgStr('unknownError'));
          closeModal();
          break;
      }
      setIsLoading(false);
    };

    if (isUpdate) {
      updatePriceLogic({...formValues, id:PriceLogic.id}, (jsonRes, status) => {
        handleResponse(jsonRes, status);
      });
    } else {
      createPriceLogic(formValues, (jsonRes, status) => {
        handleResponse(jsonRes, status);
      });
    }
  };

  const closeModalhandler = () => {
    closeModal();
  };

  const checkInput = () => {
    // if (!formValues.title.trim()) {
    //   setValidMessage(msgStr('emptyField'));
    // } else {
    //   setValidMessage('');
    // }
  };

  return isModalVisible ? (
    <View style={{ position: 'absolute', width: '100%', height: '100%' }}>
      <BasicModalContainer>
        <ModalHeader
          label={'Price Logic'}
          closeModal={() => {
            closeModalhandler();
          }}
        />
        <View style={{paddingHorizontal:20, zIndex:100}}>
          <Text style={{marginBottom:6, color:"#555555"}}>{'Brand'}</Text>
          <Picker
            selectedValue={formValues.brand_id}
            style={styles.select}
            onValueChange={(itemValue, itemIndex) => {
              console.log(itemValue);
              changeFormValue('brand_id', itemValue);
            }}
            placeholder="Select a brand"
          >
            {brandData.map((item, index) => (
              <Picker.Item key={index} label={item.brand} value={item.id} />
            ))}
          </Picker>
          <Text style={{marginBottom:6, color:"#555555"}}>{'Season'}</Text>
          <Picker
            selectedValue={formValues.season_id}
            style={styles.select}
            onValueChange={(itemValue, itemIndex) => {
              console.log(itemValue);
              changeFormValue('season_id', itemValue);
            }}
            placeholder="Select a Season"
          >
            {seasonData.map((item, index) => (
              <Picker.Item key={index} label={item.season} value={item.id} />
            ))}
          </Picker>
          <Text style={{marginBottom:6, color:"#555555"}}>{'Price table'}</Text>
          <Picker
            selectedValue={formValues.table_id}
            style={styles.select}
            onValueChange={(itemValue, itemIndex) => {
              console.log(itemValue);
              changeFormValue('table_id', itemValue);
            }}
            placeholder="Select a Price Table"
          >
            {priceTableData.map((item, index) => (
              <Picker.Item key={index} label={item.table_name} value={item.id} />
            ))}
          </Picker>
          <View style={{flexDirection:'row'}}>
            <View style={{marginBottom:10, zIndex:200}}>
              <Text style={{marginBottom:6, color:"#555555"}}>{'Start Date'}</Text>
              {Platform.OS == 'web' && 
                renderBOHFormDatePicker(formatDate(formValues.start_date), (date) => {
                  changeFormValue('start_date', date);
              })}
            </View>
            <View style={{marginBottom:10, zIndex:100, marginLeft:10}}>
              <Text style={{marginBottom:6, color:"#555555"}}>{'End Date'}</Text>
              {Platform.OS == 'web' && 
                renderBOHFormDatePicker(formatDate(formValues.end_date), (date) => {
                  changeFormValue('end_date', date);
              })}
            </View>
          </View>
        </View>
        <ModalFooter>
          <View style={{ flexDirection: 'row' }}>
            <TouchableOpacity onPress={AddButtonHandler}>
              <Text style={styles.addButton}>{isUpdate ? 'Update' : 'Add'}</Text>
            </TouchableOpacity>
          </View>
        </ModalFooter>
      </BasicModalContainer>
      {isLoading && (
        <View style={styles.overlay}>
          <ActivityIndicator size="large" color="#0000ff" />
        </View>
      )}
    </View>
  ) : null;
};

const styles = commonModalStyle;

export default EditPriceLogicModal;
