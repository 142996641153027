import React, { useEffect, useMemo, useState } from 'react';
import {
  Text,
  TouchableOpacity,
  Platform,
  View
} from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { deleteTimeClock, getCurrentStatusForEmployee, getLogDetailsData, updateEmployeeStatus } from '../../../api/Timeclock';
import { BasicLayout, CommonContainer } from '../../../common/components/CustomLayout';
import { BOHButton, BOHTlbrSearchPicker, BOHToolbar, renderBOHTlbDatePicker } from '../../../common/components/bohtoolbar';
import { BOHTBody, BOHTD, BOHTDIconBox, BOHTH, BOHTHead, BOHTR, BOHTable } from '../../../common/components/bohtable';
import { msgStr } from '../../../common/constants/Message';
import { TextMediumSize, TextdefaultSize } from '../../../common/constants/Fonts';
import { useAlertModal, useConfirmModal } from '../../../common/hooks';
import { formatDateTime, getDurationFormat } from '../../../common/utils/DateUtils';
import { getActiveUsers } from '../../../api/User';
import { API_URL } from '../../../common/constants/AppConstants';
import AddTimeClockModal from './AddTimeClockModal';

const LogDetail = ({ navigation }) => {

  const { showAlert } = useAlertModal();
  const { showConfirm } = useConfirmModal();

  const [tableData, setTableData] = useState([]);
  const [updateLogDetailListTrigger, setUpdateLogDetailListTrigger] = useState(false);
  const initialWidths = [180, 140, 120, 500, 50, 50];
  const [isAddModalVisible, setAddModalVisible] = useState(false);
  const [selectedTimeClock, setSelectedTimeClock] = useState(null);
  const [statusLoading, setStatusLoading] = useState(true);

  const [users, setUsers] = useState([]);
  const [ searchOptions, setSearchOptions ] = useState({
    user_id:null,
    date:null,
  });

  const [currentStatus, setCurrStatus] = useState<any>({
    status:0
  });
  
  const reloadInterval = 5;
  const [countdown, setCountdown] = useState(reloadInterval);

  useEffect(()=>{
    getActiveUsers((jsonRes) => {
      setUsers(jsonRes);
    });
    loadSearchOption();
  }, [])

  useEffect(()=>{
    setUpdateLogDetailListTrigger(true);
  }, [searchOptions])

  useEffect(() => {
    setStatusLoading(true);
    const interval = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          getTable();
          return reloadInterval;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [searchOptions]);

  useEffect(() => {
    const interval = setInterval(async () => {
      await getCurrentStatusForEmployee({user_id:searchOptions.user_id}, (jsonRes, status, error) => {
        switch (status) {
          case 200:
            setStatusLoading(false);
            if(jsonRes && jsonRes.length){
              if(jsonRes[0].id != currentStatus.id){
                setCurrStatus(jsonRes && jsonRes.length > 0 ? jsonRes[0] : {status:0})
              }
            }else setCurrStatus({status:0})
            break;
          case 500:
            // showAlert('error', msgStr('serverError'));
            break;
          default:
            // if (jsonRes && jsonRes.error) showAlert('error', jsonRes.error);
            // else showAlert('error', msgStr('unknownError'));
            break;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [searchOptions]);
  
  const loadSearchOption = async () => {
    const [searchOptions] = await Promise.all([
      AsyncStorage.getItem('__logdetail_options'),
    ]);
    if (searchOptions) {
      setSearchOptions(JSON.parse(searchOptions));
      setUpdateLogDetailListTrigger(true);
    }
  }

  const changeSearchOptions = (key, val) => {
    setSearchOptions(prevOptions => ({
      ...prevOptions,
      [key]: val
    }));
  }

  useEffect(() => {
    if (updateLogDetailListTrigger == true) getTable();
  }, [updateLogDetailListTrigger]);


  const openAddTimeClockModal = () => {
    setAddModalVisible(true);
    setSelectedTimeClock(null);
  };
  const closeAddTimeClockModal = () => {
    setAddModalVisible(false);
    setSelectedTimeClock(null);
  };
  const editTimeClock = (item) => {
    setSelectedTimeClock(item);
    setAddModalVisible(true);
  };

  const removeTimeClock = (id) => {
    showConfirm(msgStr('deleteConfirmStr'), () => {
      deleteTimeClock(id, (jsonRes, status, error) => {
        switch (status) {
          case 200:
            setUpdateLogDetailListTrigger(true);
            showAlert('success', jsonRes.message);
            break;
          default:
            if (jsonRes && jsonRes.error) showAlert('error', jsonRes.error);
            else showAlert('error', msgStr('unknownError'));
            break;
        }
      });
    });
  };

  const getTable = () => {
    if(!searchOptions.user_id || !searchOptions.date ) return;
    getLogDetailsData({searchOptions}, (jsonRes, status, error) => {
      switch (status) {
        case 200:
          setUpdateLogDetailListTrigger(false);
          setTableData(jsonRes);
          break;
        case 500:
          showAlert('error', msgStr('serverError'));
          break;
        default:
          if (jsonRes && jsonRes.error) showAlert('error', jsonRes.error);
          else showAlert('error', msgStr('unknownError'));
          break;
      }
    });
  };

  const getStatusFormat = (status) => {
    switch (status) {
      case 0:
        return "Clocked Out";
      case 1:
        return "Clocked In";
      case 2:
        return "Break";
      case 3:
        return "Lunch";
      default:
        return "Unknown Status";
    }
  }

  const getStatusText = (status) => {
    switch (status) {
      case 0:
        return <Text style={{color:'#D9534F', fontWeight:'bold'}}>Clocked Out</Text>;
      case 1:
        return <Text style={{color:'#4C9A2A', fontWeight:'bold'}}>Clocked In</Text>;
      case 2:
        return <Text style={{color:'#F0AD4E', fontWeight:'bold'}}>On Break</Text>;
      case 3:
        return <Text style={{color:'#2B879E', fontWeight:'bold'}}>On Lunch</Text>;
      default:
        return "Unknown Status";
    }
  }

  
  const exportForecasting = () => {
    location.href = API_URL + `/timeclock/exportdetail?date=${searchOptions.date}&user_id=${searchOptions.user_id}`
  }

  const updateStatus = (status) => {
    setStatusLoading(true);
    const payload = {
      status,
      user_id: searchOptions.user_id,
    }
    updateEmployeeStatus(payload, (jsonRes, status, error) => {
      switch (status) {
        case 200:
          const date = new Date();
          const year = date.getFullYear();
          const formattedDate = `${year}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
          changeSearchOptions('date', formattedDate);
          setUpdateLogDetailListTrigger(true);
          setCountdown(reloadInterval);
          break;
        case 500:
          showAlert('error', msgStr('serverError'));
          break;
        default:
          if (jsonRes && jsonRes.error) showAlert('error', jsonRes.error);
          else showAlert('error', msgStr('unknownError'));
          break;
      }
    });
  }

  const renderTableData = () => {
    const convertStageToString = (stage) => {
      switch (stage) {
        case null: case 'null': return 'Draft';
        case 0: case '0': return 'Draft';
        case 1: case '1': return 'Provisional';
        case 2: case '2': return 'Confirmed';
        case 3: case '3': return 'Checked out';
        case 4: case '4': return 'Checked in';
        case 5: case '5': return 'Canceled';
        default:  return 'Invalid stage';
      }
    }

    const rows = [];
    if (tableData.length > 0) {
      tableData.map((item, index) => {
        rows.push(
          <BOHTR key={index}>
            <BOHTD width={initialWidths[0]}>{formatDateTime(new Date(item.createdAt))}</BOHTD>
            <BOHTD width={initialWidths[1]} textAlign='right'>{item.status>0?getDurationFormat(item.duration):''}</BOHTD>
            <BOHTD width={initialWidths[2]}>{getStatusFormat(item.status)}</BOHTD>
            <BOHTD width={initialWidths[3]}>{item.note}</BOHTD>
            <BOHTDIconBox width={initialWidths[4]}>
              <TouchableOpacity
                onPress={() => {
                  if(item.id == currentStatus.id && item.status != 0){
                    showAlert('warning', 'Cannot edit while running timer. Please Clock out first');
                    return
                  }
                  editTimeClock(item);
                }}
              >
                <FontAwesome5 size={TextMediumSize} name="edit" color="black" />
              </TouchableOpacity>
            </BOHTDIconBox>
            <BOHTDIconBox width={initialWidths[5]}>
              <TouchableOpacity
                onPress={() => {
                  if(item.id == currentStatus.id && item.status != 0){
                    showAlert('warning', 'Cannot remove while running timer. Please Clock out first');
                    return
                  }
                  removeTimeClock(item.id);
                }}
              >
                <FontAwesome5 size={TextMediumSize} name="times" color="black" />
              </TouchableOpacity>
            </BOHTDIconBox>
          </BOHTR>
        );
      });
    } else {
      <></>;
    }
    return <>{rows}</>;
  };
// console.log(statusLoading);
// console.log('lll', statusLoading && currentStatus.status == 1)
  const tableElement = useMemo(()=>(
    <BOHTable>
      <BOHTHead>
        <BOHTR>
          <BOHTH width={initialWidths[0]}>{'From'}</BOHTH>
          <BOHTH width={initialWidths[1]}>{'Duration'}</BOHTH>
          <BOHTH width={initialWidths[2]}>{'Status'}</BOHTH>
          <BOHTH width={initialWidths[3]}>{'Note'}</BOHTH>
          <BOHTH width={initialWidths[4]}>{'EDIT'}</BOHTH>
          <BOHTH width={initialWidths[5]}>{'DEL'}</BOHTH>
        </BOHTR>
      </BOHTHead>
      <BOHTBody>
        {renderTableData()}
      </BOHTBody>
    </BOHTable>), [tableData])

    return (
      <BasicLayout
        navigation={navigation}
        goBack={() => {
          navigation.navigate('DailyLog');
        }}
        screenName={'LogDetail'}
      >
        <CommonContainer>
          <BOHToolbar style={{zIndex:100}}>
            <BOHTlbrSearchPicker
              width={125}
              boxStyle={{margin:0, marginRight:15}}
              label="Driver"
              items={[
                ...users
                  .map((item, index) => {
                    return {label: item.name, value: item.id};
                  })
                  .filter(item => item !== null)
              ]}
              selectedValue={searchOptions.user_id || ''}
              onValueChange={val=>changeSearchOptions('user_id', val)}/>
              <Text style={{marginRight:8, fontSize:TextdefaultSize}}>Date</Text>
            {Platform.OS == 'web' && 
              renderBOHTlbDatePicker(searchOptions.date, (date) => {
                const year = date.getFullYear();
                const formattedDate = `${year}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
                changeSearchOptions('date', formattedDate);
            })}
            <BOHButton
              style={{ marginLeft: 30 }}
              label='Export'
              onPress={exportForecasting}
            />
          </BOHToolbar>
          <BOHToolbar style={{zIndex:99}}>
            <View style={{width:220}}>
              <Text style={{ fontSize: TextdefaultSize }}>
                {`Curr status:  `}{getStatusText(currentStatus.status)}
              </Text>
            </View>
            <BOHButton
              label={currentStatus.status == 1 || currentStatus.status == 0 ? 'Clock in' : 'Resume'}
              style={{ backgroundColor:'#4C9A2A' }}
              disabled={statusLoading || currentStatus.status == 1}
              onPress={()=>updateStatus(1)}
            />
            <BOHButton
              label='Break'
              style={{ marginLeft: 30, backgroundColor:'#F0AD4E' }}
              disabled={statusLoading || (currentStatus.status == 0 || currentStatus.status == 2)}
              onPress={()=>updateStatus(2)}
            />
            <BOHButton
              label='Lunch'
              disabled={statusLoading || (currentStatus.status == 0 || currentStatus.status == 3)}
              style={{ marginLeft: 30, backgroundColor:'#2B879E' }}
              onPress={()=>updateStatus(3)}
            />
            <BOHButton
              label='Clock out'
              style={{ marginLeft: 30, backgroundColor:'#D9534F' }}
              disabled={statusLoading || currentStatus.status == 0}
              onPress={()=>updateStatus(0)}
            />
            <Text style={{ marginLeft: 30, fontSize: TextdefaultSize, color:'#D9534F' }}>Reload in {countdown} seconds</Text>
          </BOHToolbar>
          {tableElement}
        </CommonContainer>

        <AddTimeClockModal
          isModalVisible={isAddModalVisible}
          TimeClock={selectedTimeClock}
          setUpdateTimeClockTrigger={setUpdateLogDetailListTrigger}
          closeModal={closeAddTimeClockModal}
        />
      </BasicLayout>
    );
};

export default LogDetail;
