import { getAPICall, postAPICall } from './BaseAPI';

export const getDailyLogData = async (payload, cb = (jR, s, e)=>{}) => {
  return await postAPICall('timeclock/getdailylogdata/', payload, cb);
}

export const getLogDetailsData = async (payload, cb = (jR, s, e)=>{}) => {
  return await postAPICall('timeclock/getlogdetailsdata/', payload, cb);
}

export const getCurrentStatus = async (cb=(jR, s, e)=>{}) => {
  return await getAPICall('timeclock/getcurrentstatus/', cb);
}

export const getCurrentStatusForEmployee = async (payload, cb=(jR, s, e)=>{}) => {
  return await postAPICall('timeclock/getcurrentstatusforemployee/', payload, cb);
}

export const updateEmployeeStatus = async (payload, cb = (jR, s, e)=>{}) => {
  return await postAPICall('timeclock/updateemployeestatus/', payload, cb);
}

export const createTimeClock = async (payload, cb = (jR, s, e) => { }) => {
  return await postAPICall('timeclock/createtimeclock', payload, cb);
};

export const updateTimeClock = async (payload, cb = (jR, s, e) => { }) => {
  return await postAPICall('timeclock/updatetimeclock', payload, cb);
};

export const deleteTimeClock = async (id, cb = (jR, s, e) => { }) => {
  const payload = { id };
  return await postAPICall('timeclock/deletetimeclock', payload, cb);
}