import React, { useState, useEffect, useRef } from 'react';
import {
  Text,
  TouchableOpacity,
  View,
  ActivityIndicator,
  Platform,
} from 'react-native';

import { createBlockDate, updateBlockDate } from '../../../api/Settings';
import { BasicModalContainer, ModalHeader, ModalBody, ModalFooter, commonModalStyle } from '../../../common/components/basicmodal';
import { msgStr } from '../../../common/constants/Message';
import { useAlertModal } from '../../../common/hooks';

import LabeledTextInput from '../../../common/components/bohform/LabeledTextInput';
import { renderBOHFormDatePicker } from '../../../common/components/bohform';
import { formatDate } from '../../../common/utils/DateUtils';
import NumericInput from '../../../common/components/formcomponents/NumericInput';

interface FormValues {
  title: string;
  date: Date;
  duration: number;
}

const AddBlockDateModal = ({
  isModalVisible,
  BlockDate,
  setUpdateBlockDateTrigger,
  closeModal,
}) => {
  const isUpdate = BlockDate ? true : false;
  const inputRef = useRef(null);

  const { showAlert } = useAlertModal();
  const [ValidMessage, setValidMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [formValues, setFormValues] = useState<FormValues>({
    title: '',
    date: new Date(),
    duration: 1,
  });

  useEffect(() => {
    if (isModalVisible && BlockDate) {
      setFormValues({
        title: BlockDate.title || '',
        date: BlockDate?.date? new Date(`${BlockDate.date} 00:00:00`) : new Date(),
        duration: BlockDate.duration || 1,
      })
    } else {
      setFormValues({
        title: '',
        date: new Date(),
        duration: 1,
      })
    }
  }, [isModalVisible]);

  const changeFormValue = (name, value) => {
    setFormValues(prev => ({
      ...prev,
      [name]:value,
    }))
  }

  const AddButtonHandler = () => {
    if (!formValues.title.trim()) {
      setValidMessage(msgStr('emptyField'));
      return;
    }

    setIsLoading(true);

    const handleResponse = (jsonRes, status) => {
      switch (status) {
        case 201:
          showAlert('success', jsonRes.message);
          setUpdateBlockDateTrigger(true);
          closeModal();
          break;
        case 409:
          setValidMessage(jsonRes.error);
          break;
        default:
          if (jsonRes && jsonRes.error) showAlert('error', jsonRes.error);
          else showAlert('error', msgStr('unknownError'));
          closeModal();
          break;
      }
      setIsLoading(false);
    };

    if (isUpdate) {
      updateBlockDate({...formValues, id:BlockDate.id}, (jsonRes, status) => {
        handleResponse(jsonRes, status);
      });
    } else {
      createBlockDate(formValues, (jsonRes, status) => {
        handleResponse(jsonRes, status);
      });
    }
  };

  const closeModalhandler = () => {
    closeModal();
  };

  const checkInput = () => {
    if (!formValues.title.trim()) {
      setValidMessage(msgStr('emptyField'));
    } else {
      setValidMessage('');
    }
  };

  return isModalVisible ? (
    <View style={{ position: 'absolute', width: '100%', height: '100%' }}>
      <BasicModalContainer>
        <ModalHeader
          label={'Block Date'}
          closeModal={() => {
            closeModalhandler();
          }}
        />
        <View style={{paddingHorizontal:20, zIndex:100}}>
          <LabeledTextInput
            label='Title'
            placeholder="Title"
            value={formValues.title}
            onChangeText={(val)=>changeFormValue('title', val)}
            placeholderTextColor="#ccc"
            onBlur={checkInput}
          />
          <View style={{marginBottom:10, zIndex:101}}>
            <Text style={{marginBottom:6, color:"#555555"}}>{'Date'}</Text>
            {Platform.OS == 'web' && 
              renderBOHFormDatePicker(formatDate(formValues.date), (date) => {
                changeFormValue('date', date);
            })}
          </View>
          <Text style={styles.label}>Duration</Text>
          <NumericInput
            validMinNumber={0}
            style={styles.input}
            placeholder="Duration"
            value={formValues?.duration?.toString()??''}
            onChangeText={val=>changeFormValue('duration', val)}
            placeholderTextColor="#ccc"
          />
        </View>
        <ModalFooter>
          <View style={{ flexDirection: 'row' }}>
            <TouchableOpacity onPress={AddButtonHandler}>
              <Text style={styles.addButton}>{isUpdate ? 'Update' : 'Add'}</Text>
            </TouchableOpacity>
          </View>
        </ModalFooter>
      </BasicModalContainer>
      {isLoading && (
        <View style={styles.overlay}>
          <ActivityIndicator size="large" color="#0000ff" />
        </View>
      )}
    </View>
  ) : null;
};

const styles = commonModalStyle;

export default AddBlockDateModal;
