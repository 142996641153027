import React, { useState, useEffect, useRef } from 'react';
import {
  Text,
  TouchableOpacity,
  View,
  ActivityIndicator,
  Platform,
} from 'react-native';

import { createTimeClock, updateTimeClock } from '../../../api/Timeclock';
import { BasicModalContainer, ModalHeader, ModalBody, ModalFooter, commonModalStyle } from '../../../common/components/basicmodal';
import { msgStr } from '../../../common/constants/Message';
import { useAlertModal } from '../../../common/hooks';

import LabeledTextInput from '../../../common/components/bohform/LabeledTextInput';
import { renderBOHFormDatePicker } from '../../../common/components/bohform';
import { formatDate, formatDateTime, getDurationFormat } from '../../../common/utils/DateUtils';
import NumericInput from '../../../common/components/formcomponents/NumericInput';
import { TextInput } from 'react-native-gesture-handler';

interface FormValues {
  note: string;
  createdAt: Date;
  duration: number;
  status: number;
  D: number;
  H: number;
  m: number;
  s: number;
}

const AddTimeClockModal = ({
  isModalVisible,
  TimeClock,
  setUpdateTimeClockTrigger,
  closeModal,
}) => {
  const isUpdate = TimeClock ? true : false;
  const inputRef = useRef(null);

  const { showAlert } = useAlertModal();
  const [ValidMessage, setValidMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [formValues, setFormValues] = useState<FormValues>({
    note: '',
    createdAt: new Date(),
    duration: 0,
    status: 0,
    D: 0,
    H: 0,
    m: 0,
    s: 0,
  });

  useEffect(() => {
    if (isModalVisible && TimeClock) {
      setFormValues({
        note: TimeClock.note || '',
        createdAt: TimeClock?.createdAt? new Date(`${TimeClock.createdAt}`) : new Date(),
        duration: TimeClock.duration || 0,
        status: TimeClock.status || 0,
        D: TimeClock.duration ? Math.floor(TimeClock.duration / (1000 * 60 * 60 * 24)) : 0,
        H: TimeClock.duration ? Math.floor((TimeClock.duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) : 0,
        m: TimeClock.duration ? Math.floor((TimeClock.duration % (1000 * 60 * 60)) / (1000 * 60)) : 0,
        s: TimeClock.duration ? Math.floor((TimeClock.duration % (1000 * 60)) / 1000) : 0
      })
    } else {
      setFormValues({
        note: '',
        createdAt: new Date(),
        duration: 0,
        status: 0,
        D: 0,
        H: 0,
        m: 0,
        s: 0,
      })
    }
  }, [isModalVisible]);

  const changeFormValue = (name, value) => {
    setFormValues(prev => ({
      ...prev,
      [name]:value,
    }))
  }

  const AddButtonHandler = () => {
    // if (!formValues.note.trim()) {
    //   setValidMessage(msgStr('emptyField'));
    //   return;
    // }

    setIsLoading(true);

    const handleResponse = (jsonRes, status) => {
      switch (status) {
        case 201:
          showAlert('success', jsonRes.message);
          setUpdateTimeClockTrigger(true);
          closeModal();
          break;
        case 409:
          setValidMessage(jsonRes.error);
          break;
        default:
          if (jsonRes && jsonRes.error) showAlert('error', jsonRes.error);
          else showAlert('error', msgStr('unknownError'));
          closeModal();
          break;
      }
      setIsLoading(false);
    };

    if (isUpdate) {
      updateTimeClock({...formValues, id:TimeClock.id}, (jsonRes, status) => {
        handleResponse(jsonRes, status);
      });
    } else {
      createTimeClock(formValues, (jsonRes, status) => {
        handleResponse(jsonRes, status);
      });
    }
  };

  const closeModalhandler = () => {
    closeModal();
  };

  const checkInput = () => {
    // if (!formValues.note.trim()) {
    //   setValidMessage(msgStr('emptyField'));
    // } else {
    //   setValidMessage('');
    // }
  };

  const updateDuration = (unit, val) => {
    const value = parseInt(val, 10);
    if (!isNaN(value)) {
      let isValid = true;
      let updatedValues = { ...formValues };
  
      switch (unit) {
        case 'D':
          if (value < 0) {
            updatedValues.D = 0;
          } else {
            updatedValues.D = value;
          }
          break;
        case 'H':
          if (value < 0 || value >= 24) {
            isValid = false; 
          } else {
            updatedValues.H = value;
          }
          break;
        case 'm':
          if (value < 0 || value >= 60) {
            isValid = false;
          } else {
            updatedValues.m = value;
          }
          break;
        case 's':
          if (value < 0 || value >= 60) {
            isValid = false;
          } else {
            updatedValues.s = value;
          }
          break;
        default:
          isValid = false;
      }
  
      if (isValid) {
        const totalDuration = 
          (updatedValues.D * 24 * 60 * 60 * 1000) +
          (updatedValues.H * 60 * 60 * 1000) +
          (updatedValues.m * 60 * 1000) +
          (updatedValues.s * 1000);
  
        updatedValues.duration = totalDuration;
        setFormValues(updatedValues);
      }
    }
  }
  

  return isModalVisible ? (
    <View style={{ position: 'absolute', width: '100%', height: '100%' }}>
      <BasicModalContainer>
        <ModalHeader
          label={'Time Log'}
          closeModal={() => {
            closeModalhandler();
          }}
        />
        <View style={{paddingHorizontal:20, zIndex:100, width:400}}>
          <View style={{marginBottom:20, zIndex:101}}>
            <Text style={{marginBottom:6, color:"#555555"}}>{`From: ${formatDateTime(new Date(formValues.createdAt))}`}</Text>
          </View>
          {formValues.status !=0 && <>
            <Text style={{marginBottom:6, color:"#555555"}}>{`Duration`}</Text>
            <View style={{flexDirection:'row', justifyContent:'space-between', paddingBottom:10}}>
              <View style={{flexDirection:'row', alignItems:'center'}}>
                <TextInput 
                  value={formValues.D.toString()}
                  onChangeText={(val)=>{updateDuration('D', val)}}
                  style={[styles.input, {width:50, margin:0, marginRight:6, paddingVertical:4}]}
                />
                <Text>D</Text></View>
              <View style={{flexDirection:'row', alignItems:'center'}}>
                <TextInput 
                  value={formValues.H.toString()}
                  onChangeText={(val)=>{updateDuration('H', val)}}
                  style={[styles.input, {width:50, margin:0, marginRight:6, paddingVertical:4}]}
                />
                <Text>H</Text></View>
              <View style={{flexDirection:'row', alignItems:'center'}}>
                <TextInput 
                  value={formValues.m.toString()}
                  onChangeText={(val)=>{updateDuration('m', val)}}
                  style={[styles.input, {width:50, margin:0, marginRight:6, paddingVertical:4}]}
                />
                <Text>m</Text></View>
              <View style={{flexDirection:'row', alignItems:'center'}}>
                <TextInput 
                  value={formValues.s.toString()}
                  onChangeText={(val)=>{updateDuration('s', val)}}
                  style={[styles.input, {width:50, margin:0, marginRight:6, paddingVertical:4}]}
                />
                <Text>s</Text></View>
            </View>
          </>}
          <LabeledTextInput
            label='Note'
            placeholder="note"
            value={formValues.note}
            onChangeText={(val)=>changeFormValue('note', val)}
            placeholderTextColor="#ccc"
            onBlur={checkInput}
          />
        </View>
        <ModalFooter>
          <View style={{ flexDirection: 'row' }}>
            <TouchableOpacity onPress={AddButtonHandler}>
              <Text style={styles.addButton}>{isUpdate ? 'Update' : 'Add'}</Text>
            </TouchableOpacity>
          </View>
        </ModalFooter>
      </BasicModalContainer>
      {isLoading && (
        <View style={styles.overlay}>
          <ActivityIndicator size="large" color="#0000ff" />
        </View>
      )}
    </View>
  ) : null;
};

const styles = commonModalStyle;

export default AddTimeClockModal;
